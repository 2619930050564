import { render, staticRenderFns } from "./adManger.vue?vue&type=template&id=b059c736&scoped=true&"
import script from "./adManger.vue?vue&type=script&lang=js&"
export * from "./adManger.vue?vue&type=script&lang=js&"
import style0 from "./adManger.vue?vue&type=style&index=0&id=b059c736&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b059c736",
  null
  
)

export default component.exports